import React from 'react';
import styled from 'styled-components';
import { colors, rfs } from 'utils/styles/theme';

const Layout = styled.div`
	/* border: 1px solid ${colors.pattensBlue}; */
  width: 40px;
  height: 40px;
	border-radius: 50%;
	position: absolute;
  left: calc(50% - 20px);
  bottom: 16px;
	animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 12px;
		left: 14px;
		width: 9px;
		height: 9px;
		border-left: 1px solid ${colors.pattensBlue};
  	border-bottom: 1px solid ${colors.pattensBlue};
    transform: rotate(-45deg);
	}
  @keyframes down {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translateY(15px);
    }
    40% {
      transform: translate(0);
    }
  }
`
const ScrollDown = () => (
  <Layout></Layout>
);

export default ScrollDown;
