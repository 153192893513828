import React, { useContext, useEffect, useRef, useState } from 'react';
import { motion, useCycle } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { gsap } from 'gsap/dist/gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import dynamic from 'next/dynamic';

import { BaseContextType, BaseContext } from 'u9/contexts/base';
import { useWindowSize } from 'u9/hooks';
import Link from 'next/link';

/* const Pixi = dynamic(() => import('u9/containers/landing/pixi'), {
  ssr: false,
}); */

import {
  Layout,
  LayoutTitle,
  Title,
  LayoutBody,
  Caption,
  Desc,
  LayoutLeft,
  LayoutRight,
  Content,
  SeeOurWorkDesc,
  LinkWrapper,
  LayoutSeeOurWork,
  TitleAnimated,
  LayoutNews,
  NewsTitle,
  NewsLink,
  NewsWrapperStyled,
  NewsDesc,
  NewsImageWrapper,
  NewsWrapperStyledInner,
  NewsImageWrapperInner,
  LayoutNewsTitle,
  LayoutNewsBox,
  GetInTouchWrapper,
  LayoutSeeOurWorkGetInTouchSFCWrapper,
  LayoutLeftGetInTouchSFCWrapper,
  LayoutNewsBg,
  GetInTouchWrapperSide,
  GetInTouchSide,
} from './home.styles';

import { cmsApiClient } from 'utils/api/cms';
import { ISR_TIMEOUT } from 'utils/config';
import { GetServerSideProps } from 'next';
import { gql } from '@apollo/client';
import { Body2, Heading1, Heading2 } from 'u9/contexts/base.types';
import ScrollDown from 'u9/components/scrollDown';
import BaseLink from 'u9/components/baseLink/baseLink';
import Head from 'u9/components/Head';
import HaveQuestions from '../haveQuestions/haveQuestion';
import { GetInTouch } from '../aboutUs/aboutUs.styles';
import { colors } from 'utils/styles/theme';

interface SeeOurWork {
  body2: [Body2];
}
interface HomeProps {
  title: Heading1,
  heading2: Heading2,
  seeOurWork: SeeOurWork,
}

const GetInTouchSFC = props => {
  const { locale } = useRouter();
  return (
    <GetInTouchWrapper>
      <GetInTouch>
        Have Questions?
      <br />
      <Link href="/get-in-touch" locale={locale}>
        <BaseLink
          label={'Get in Touch'}
          color={'pattensBlue'}
        />
      </Link>
      </GetInTouch>
    </GetInTouchWrapper>
  )
}

const NewsWrapper = ({ title, desc, link, linkTxt, image, ...restProps }) => {

  const [isHovered, toggleHovered] = useCycle<boolean>(false, true);


  return (
      <NewsWrapperStyled>

        <NewsWrapperStyledInner>

          <NewsTitle>{title}</NewsTitle>
          <NewsImageWrapperInner>
            <img src={image || 'https://storage.googleapis.com/u9t-website-3111-bc9a-uploads/PITCH_DECK_1_651d776157/PITCH_DECK_1_651d776157.png'} alt={title} />
          </NewsImageWrapperInner>
          <NewsDesc>{desc}</NewsDesc>
          {link != '' && <Link href={link} passHref>
              <NewsLink isHovered={isHovered}>
                <BaseLink label={linkTxt} color={'pattensBlue'} />
              </NewsLink>
            </Link>
          }
          {link == '' && linkTxt && <NewsLink isHovered={false}>{linkTxt}</NewsLink>}
        </NewsWrapperStyledInner>


      </NewsWrapperStyled>

  );
};


const Home = ({ title, heading2, seeOurWork, ...restProps }: HomeProps) => {
  const [mounted, setMounted] = useState(false);
  const { locale } = useRouter();
  const windowSize = useWindowSize();
  //const [logoScaleOffset, setLogoScaleOffset] = useState({ val: 1 });

  const { setMenuHidden, setMenuActive, setPIXIActive, PIXIScaleOffset, setPIXIScaleOffset}: BaseContextType = useContext(
    BaseContext
  );

  const layoutRef = useRef<HTMLDivElement>(null);
  const layoutLeftRef = useRef<HTMLDivElement>(null);
  const layoutRightRef = useRef<HTMLDivElement>(null);

  const layoutTitleRef = useRef<HTMLDivElement>(null);
  const titleWrapperRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const layoutBodyRef = useRef<HTMLDivElement>(null);
  const captionRef = useRef<HTMLDivElement>(null);
  const descRef = useRef<HTMLDivElement>(null);
  const layoutRightWrapperRef = useRef<HTMLDivElement>(null);
  const scrollDownRef = useRef(null);
  const sideBarRef = useRef(null)

  const titleAnimatedRef = useRef(null);
  const seeOurWorkRef = useRef(null);

  const [layoutWidth, setLayoutWidth] = useState(100);
  const [layoutWidthRev, setLayoutWidthRev] = useState(0);
  const [sowr, setSowr] = useState(false);

  const layoutTitleAnimatedRef = useRef(null);
  const linkWrapperRef = useRef(null);

  const posSide = (e = 0) => {
    const calcWidth = 100 - ( windowSize.width > 1024 ? 25 : 8 ) * e;
    setLayoutWidth(calcWidth);

    let calcWidthRev = (100 - calcWidth)
    calcWidthRev = -25 + calcWidthRev
    setLayoutWidthRev(calcWidthRev);
  }

  useEffect(() => {
    setPIXIScaleOffset(1)
    setPIXIActive(true);

    const titleAnimatedSplitText = new SplitText(titleAnimatedRef.current, {
      type: 'lines',
    });
    gsap.set(titleAnimatedRef.current, {
      "scale": 1.3545
    })
    gsap.set(titleRef.current, {
      "scale": 1.3545
    })

    posSide();

    gsap.from(titleAnimatedSplitText.lines, {
      scrollTrigger: {
        id: 'titleAnimatedRef',
        trigger: layoutTitleAnimatedRef.current,
        start: 'center center',
        end: 'bottom top',
        scrub: 0.3612,
        pin: true,
        pinSpacing: false,
        // markers: true,

        onUpdate: e => {
          const _logoScaleOffsetVal = Math.min(55, 1 + 55 * e.progress * 1.25);
          setPIXIScaleOffset(_logoScaleOffsetVal);
          if (e.progress > .01) {
            gsap.to(scrollDownRef.current, {opacity: 0, duration: 0.3612});
          } else {
            gsap.to(scrollDownRef.current, {opacity: 1, duration: 0.3612});
          }
          if (e.progress > .5) { setMenuHidden(false);
          } else {
            setMenuHidden(true);
            setMenuActive(false);
          }
        },
        onEnter: () => {
          gsap.set(titleRef.current, {"display": "none"})
        },
        onEnterBack: e => {
          gsap.set(titleAnimatedRef.current, {"display": "unset"})
          gsap.set(titleRef.current, {"display": "none"})
        },
        onLeave: e => {
          gsap.set(titleAnimatedRef.current, {"display": "none"})
          gsap.set(titleRef.current, {"display": "unset"})
        },
      },
      opacity: 0,
      xPercent: -25,
      stagger: 0.2,
    });

    gsap.to(layoutBodyRef.current, {
      y: -(windowSize.height * 0.1),
      scrollTrigger: {
        id: "layoutBodyRef",
        trigger: layoutBodyRef.current,
        start: 'top bottom',
        end: `top 75%`,
        scrub: 0.3612,
        // markers: true,
        invalidateOnRefresh: true,
        onUpdate: (e) => {
          posSide(e.progress);
        }
      },

    });


    return () => {
    }
  }, []);

  useEffect(() => {
    ScrollTrigger.refresh()
  }, [windowSize]);


  useEffect(() => {
    setMounted(true);
    return () => {

      ScrollTrigger.getById('titleAnimatedRef')?.kill();
      ScrollTrigger.getById('layoutBodyRef')?.kill();

      setMounted(false);
      setPIXIActive(false);
    };
  }, []);

  const [ isSidebarActive, setSidebarActive] = useState(false);
  const seeOurWorkDescRef = useRef(null);
  const [descAnim, setDescAnim] = useState(null);

  useEffect(() => {
    if (!descAnim) {
      const childSplit = new SplitText(seeOurWorkDescRef.current, { type: 'lines', linesClass: 'split-child' });
      const parentSplit = new SplitText(seeOurWorkDescRef.current, { type: 'lines' });

      const descAnimTl = gsap.timeline();

      descAnimTl.paused(true);
      descAnimTl
        .from(childSplit.lines, { duration: 0.3612, yPercent: 100, opacity: 0, ease: 'power4', stagger: 0.1, delay: 0.1806 })

      setDescAnim(descAnimTl);
    }
    if (descAnim) isSidebarActive ? descAnim.play() : descAnim.reverse();
  }, [isSidebarActive]);

  return (
    // <motion.div
    //   initial={{ opacity: 0}}
    //   animate={{ opacity: 1}}
    //   exit={{ opacity: 0}}
    //   {...restProps}
    // >
    <div>
      <Head title={"U9T: Home"}/>
      <LayoutTitle ref={layoutTitleAnimatedRef}>
        <TitleAnimated ref={titleAnimatedRef} dangerouslySetInnerHTML={{ __html: title.heading1 }} />
      </LayoutTitle>
      <Layout ref={layoutRef} onClick={()=> { setMenuActive(false) }}>
        <LayoutLeft ref={layoutLeftRef} layoutWidth={layoutWidth}>
          <LayoutTitle ref={layoutTitleRef}>
            <Title ref={titleRef} dangerouslySetInnerHTML={{ __html: title.heading1 }} />
          </LayoutTitle>
          <LayoutBody ref={layoutBodyRef}>
            <Caption ref={captionRef}>
              Scroll <br />
              to learn more
            </Caption>
            <Desc ref={descRef}>
                We seek problems to solve and processes to de-risk through fast prototyping, research and development (R&D) and IP generation.
                <br/>
                <br/>
                U9T’s approach is multidisciplinary. Our designers, technologists and engineers apply their expertise creatively across tech and industry boundaries.
            </Desc>
          </LayoutBody>
          <LayoutNews>
            <LayoutNewsBg />
            <LayoutNewsTitle>
                Latest News
            </LayoutNewsTitle>
            <LayoutNewsBox>
              <NewsWrapper
                title={'Hyper Hits the Road in 2022'}
                desc={'In 2022 we are bringing our HyperCar out to clients to experience. It is a mixed reality experience that conveys our learning within this hyper space. If you are interested in a demo you have to physically visit us or organise an event.'}
                link={'https://www.hypercrc.com/'}
                linkTxt={'Find out more'}
                image={'https://storage.googleapis.com/u9t-website-3111-bc9a-uploads/Screenshot_2022_02_16_at_14_35_56_f00a5646f8/Screenshot_2022_02_16_at_14_35_56_f00a5646f8.png'}
              />

              <NewsWrapper
                title={'Spatial.Energy Launches'}
                desc={'We have launched the Energy Open Piazza platform into a newco called Spatial.Energy which is now looking to bring together opportunities in the built environment energy sector. We are looking for investors and partners.'}
                link={'https://energyopenpiazza.io/'}
                linkTxt={'Find out more'}
                image={'https://storage.googleapis.com/u9t-website-3111-bc9a-uploads/SINGLE_BUILDING_ADDRESS_INPUT_bf9542013b/SINGLE_BUILDING_ADDRESS_INPUT_bf9542013b.png'}
              />

              <NewsWrapper
                title={'CogiGames for Stroke Rehabilitation'}
                desc={'In collaboration with startup Cogitat and with support from Innovate UK for Audience of the Future we are working on EEG integration based immersive games targeting stroke rehabilitation scenarios.'}
                link={'/work/cogigame'}
                linkTxt={'Find out more'}
                image={'https://storage.googleapis.com/u9t-website-3111-bc9a-uploads/Ford_Execution_image_791e9ebf2e/Ford_Execution_image_791e9ebf2e.png'}
              />

              <NewsWrapper
                title={'UX/UI for Hololens 2'}
                desc={'Still to be released UX/UI work for a well known software vendor. Mixed reality is an exciting territory in which to deliver software that is easy to use and intuitive.'}
                link={'/work/itwin-xr'}
                linkTxt={'Find out more'}
                image={''}
              />
            </LayoutNewsBox>


            <LayoutLeftGetInTouchSFCWrapper>

<GetInTouchSFC />
</LayoutLeftGetInTouchSFCWrapper>
          </LayoutNews>



        </LayoutLeft>
      </Layout>
      <LayoutSeeOurWork layoutWidth={layoutWidthRev} ref={layoutRightWrapperRef} onMouseEnter={() => setSidebarActive(true)} onMouseLeave={() => setSidebarActive(false)}>
        <Content>
          <SeeOurWorkDesc>
            {
              seeOurWork.body2.map(e => (
                e.body2
              ))
            }
          </SeeOurWorkDesc>
            <LinkWrapper ref={linkWrapperRef}>
            <div ref={seeOurWorkRef} >
              <Link href="/our-work" locale={locale} >
                <BaseLink label="See Our Work" color={'pattensBlue'} onClick={() => {
                  setMenuHidden(true);
                }}/>
              </Link>
            </div>
            <img src="/svgs/arrow.svg" height={32} width={32} />
          </LinkWrapper>
          <LayoutSeeOurWorkGetInTouchSFCWrapper>
            <GetInTouchWrapperSide >
              <GetInTouchSide>
                Have Questions?
              <br />
              <Link href="/get-in-touch" locale={locale}>
                <BaseLink
                  label={'Get in Touch'}
                  color={'pattensBlue'}
                />
              </Link>
              </GetInTouchSide>
            </GetInTouchWrapperSide>
          </LayoutSeeOurWorkGetInTouchSFCWrapper>

        </Content>

      </LayoutSeeOurWork>
      <div ref={scrollDownRef}>
        <ScrollDown />
      </div>
    </div>
    // </motion.div>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async ({}) => {
  const response = await cmsApiClient.query({
    query: gql`
      {
        home {
          title {
            heading1
          },
          heading2 {
            heading2
          },
          seeOurWork {
            body2 {
              body2
            }
          }
        }
      }
    `,
  });
  return {
    props: response.data.home
  };
};
