import styled from 'styled-components';
import { setTypography, calcPxToRem, setVh, hover } from 'utils/styles/mixins';
import { colors, rfs } from 'utils/styles/theme';
import {
  mediaDesktop,
  mediaDesktopMax,
  mediaMobile,
} from 'utils/styles/responsive';

export const Layout = styled.div`
  color: ${colors.pattensBlue};
  display: flex;
  /* background-color: ${colors.comet}; */
`;

export const LayoutLeft = styled.div<{ layoutWidth: number }>`
  width: ${({ layoutWidth }) => layoutWidth}vw;
  flex-shrink: 0;
  border-right: 1px;
  border-style: solid;
  border-color: ${colors.pattensBlue80};
  transition: width 0.1806s;
`;

export const LayoutRight = styled.div`
  width: 25vw;
  flex-shrink: 0;
  border-left: 1px;
  border-style: solid;
  border-color: ${colors.pattensBlue80};
  position: relative;
`;

export const LayoutTitle = styled.div`
  min-height: ${setVh(100)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${setTypography('heading1')}
  width: 100%;
`;

export const Title = styled.div`
  color: ${colors.pattensBlue};
`;
export const TitleAnimated = styled.div`
  color: ${colors.pattensBlue};
`;

export const LayoutBody = styled.div`
  border-top: 1px;
  border-style: solid;
  border-color: ${colors.pattensBlue80};
  padding-left: 15%;
  padding-right: 25%;
`;

export const Caption = styled.div`
  ${rfs(`${calcPxToRem(60)}rem`, `margin-top`)}
  ${rfs(`${calcPxToRem(80)}rem`, `margin-bottom`)}
  ${setTypography('caption')}
`;

export const Desc = styled.div`
  ${setTypography('heading2')}
  ${rfs(`${calcPxToRem(80)}rem`, `margin-top`)}
  ${rfs(`${calcPxToRem(60)}rem`, `margin-bottom`)}
`;

export const LayoutSeeOurWork = styled.div<{ layoutWidth: number }>`
  position: fixed;
  height: ${setVh(100)};
  right: ${({ layoutWidth }) => layoutWidth}vw;
  top: 0;
  width: 25vw;
  color: ${colors.pattensBlue};

  background-size: 200% 200%;
  transition: background-position 0.3612s, right 0.1806s;
  background-image: linear-gradient(
    to left,
    ${colors.balticSea} 50%,
    transparent 0
  );
  background-position: left;
  ${hover(`
    background-position: right;
  `)}
`;

export const Content = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 5%;
`;

export const SeeOurWorkDesc = styled.div`
  ${rfs(`0.875rem`, `font-size`)}
  margin-bottom: 10%;
  margin-left: 16%;
  margin-right: calc(5% + 16px);

  ${mediaDesktopMax(`
    display: none;
  `)}
`;
export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16%;
  margin-right: calc(5% + 16px);

  ${setTypography('heading5')};
  > div :nth-child(1) {
    min-width: 150px;
  }
  ${rfs(`${calcPxToRem(40)}rem`, `margin-bottom`)}
  ${mediaDesktopMax(`
    transform: rotate(-90deg);
    transform-origin: left center;
    margin-bottom: unset;
  `)}
`;

export const LayoutNews = styled.div`
  position: relative;
  border-top: 1px;
  border-style: solid;
  border-color: ${colors.pattensBlue80};

  background-color: ${colors.balticSea};
`;

export const LayoutNewsBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background-color: ${colors.balticSea};
  z-index: -1;
`;

export const LayoutNewsTitle = styled.div`
  ${setTypography('heading2')}
  ${rfs(`${calcPxToRem(80)}rem`, `margin-top`)}
  ${rfs(`${calcPxToRem(40)}rem`, `margin-bottom`)}
  padding-left: 15%;
  padding-right: 15%;
`;

export const LayoutNewsBox = styled.div`
  padding-left: 15%;
  padding-right: 15%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
  column-gap: 24px;
  row-gap: 48px;
  ${rfs(`${calcPxToRem(80)}rem`, `padding-bottom`)};
  ${mediaDesktopMax(`

  `)}/* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ;
  row-gap: ${calcPxToRem(20)}; */
`;

export const NewsTitle = styled.div`
  ${setTypography('body1')}
  ${rfs(`${calcPxToRem(40)}rem`, `margin-top`)};
  ${rfs(`${calcPxToRem(40)}rem`, `margin-bottom`)};
  cursor: default;
  min-height: 40px;
`;

export const NewsDesc = styled.div`
  ${rfs(`0.7224rem`, `font-size`)}
  ${rfs(`${calcPxToRem(40)}rem`, `margin-top`)};
  ${rfs(`${calcPxToRem(40)}rem`, `margin-bottom`)};

  cursor: default;
`;

export const NewsLink = styled.div<{ isHovered: boolean }>`
  ${setTypography('body2')}
`;

export const NewsWrapperStyled = styled.div``;

export const NewsWrapperStyledInner = styled.div``;

export const NewsImageWrapperInner = styled.div`
  position: relative;

  width: 100%;

  > img {
    width: 100%;
    height: 166px;
    ${mediaMobile(`
      height: unset;
    `)}
    border-radius: 7.5px;
    object-fit: cover;
  }
`;

export const NewsImageWrapper = styled.div`
  position: relative;
  ${rfs(`${calcPxToRem(300)}rem`, `width`)};
  display: unset;
  ${mediaMobile(`
    display: none;
  `)}

  //height: 40px;
  background-color: 'pink';

  > img {
    width: 100%;
    border-radius: 7.5px;
    object-fit: contain;
  }
`;

export const GetInTouchWrapperSide = styled.div`
  ${setTypography('heading5')}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-top: 1px;
  border-style: solid;
  border-color: ${colors.pattensBlue80};

  min-height: clamp(165px, 25vh, 165px);
  ${mediaDesktopMax(`
    background-color: ${colors.balticSea};
    min-height: clamp(165px, 25vh, 270px);
  `)}
`;

export const GetInTouchSide = styled.div`
  margin-left: 16%;
  margin-right: calc(5% + 16px);
`;

export const GetInTouchWrapper = styled.div`
  ${setTypography('heading5')}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px;
  border-style: solid;
  border-color: ${colors.pattensBlue10};
  min-height: clamp(165px, 25vh, 165px);
  ${mediaDesktopMax(`
    background-color: ${colors.balticSea};
    min-height: clamp(165px, 25vh, 270px);
  `)}
`;

export const LayoutSeeOurWorkGetInTouchSFCWrapper = styled.div`
  ${mediaDesktopMax(`
    display: none;
  `)}
`;

export const LayoutLeftGetInTouchSFCWrapper = styled.div`
  display: none;
  ${mediaDesktopMax(`
    display: unset;
  `)}
`;
