import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  DefaultOptions,
} from '@apollo/client';
import fetch from 'isomorphic-unfetch';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpLink = createHttpLink({
  uri:
    process.browser && process.env.ENV === 'local'
      ? process.env.CMS_GRAPHQL_URL_LOCAL
      : process.env.CMS_GRAPHQL_URL,
  fetch,
});

export const cmsApiClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions,
});
