import styled from 'styled-components';
import { setTypography, calcPxToRem } from 'utils/styles/mixins';
import { mediaDesktop, mediaMobile } from 'utils/styles/responsive';
import { colors, rfs } from 'utils/styles/theme';
import { createRFS } from 'utils/styles/rfs';

const _rfs = createRFS({ rfsBreakpoint: `3840px` });

export const Layout = styled.div`
  background-color: ${colors.balticSea};
  color: ${colors.pattensBlue};
  display: flex;
  ${mediaMobile(`
    flex-direction: column;
  `)}
`;

export const TitleAnimated = styled.div`
  ${setTypography('heading2')}
  position: absolute;
`;

export const LayoutLeft = styled.div`
  width: clamp(350px, 35vw, 35vw);
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${colors.balticSea};
  ${mediaMobile(`
    width: 100vw;
    min-height: unset;

  `)}
`;

export const LayoutTitleWrapper = styled.div``;

export const Title = styled.div`
  ${setTypography('heading2')}
  ${_rfs(`${calcPxToRem(190)}rem`, `margin-left`)}
  ${_rfs(`${calcPxToRem(190)}rem`, `margin-right`)}

  ${rfs(`${calcPxToRem(150)}rem`, `margin-top`)}
  ${rfs(`${calcPxToRem(150)}rem`, `margin-bottom`)}
`;

export const LayoutDesc = styled.div`
  display: flex;
  flex-direction: column;

  > div :nth-child(1) {
    ${setTypography('body2')}
    ${_rfs(`${calcPxToRem(190)}rem`, `padding-left`)}
    ${_rfs(`${calcPxToRem(190)}rem`, `padding-right`)}
    ${_rfs(`${calcPxToRem(90)}rem`, `margin-top`)}
    ${_rfs(`${calcPxToRem(90)}rem`, `margin-bottom`)}
    display: none;
    > div {
      margin-bottom: 32px;
      > div {
        margin-bottom: 16px;
        > div {
          margin-bottom: 16px;
        }
      }
      > div :nth-child(1) {
        ${setTypography('subtitle2')}
      }
    }
    ${mediaMobile(`
      margin-top: unset;
      display: block;
    `)}
    ${mediaDesktop(`
      display: block;
    `)}
  }
`;

export const GetInTouchWrapper = styled.div`
  ${setTypography('heading5')}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px;
  border-style: solid;
  border-color: ${colors.pattensBlue10};
  min-height: clamp(165px, 25vh, 270px);
`;

export const GetInTouch = styled.div``;

export const LayoutRight = styled.div`
  border-left: 1px;
  border-style: solid;
  border-color: ${colors.pattensBlue10};
  display: none;
  min-height: 100vh;
  height: 100%;
  width: 65vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  ${rfs(`${calcPxToRem(100)}rem`, `row-gap`)}
  ${_rfs(`${calcPxToRem(190)}rem`, `padding-left`)}
  ${_rfs(`${calcPxToRem(190)}rem`, `padding-right`)}
  ${rfs(`${calcPxToRem(100)}rem`, `padding-top`)}
  ${rfs(`${calcPxToRem(100)}rem`, `padding-bottom`)}
  > div :nth-child(1) {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 700px;
    width: 100%auto;
    > div :nth-child(1) {
      width: 100%;
    }
  }
  ${mediaMobile(`
    width: 100vw;
    border-top: 1px;
    border-style: solid;
    border-color: ${colors.pattensBlue10};
  `)}
`;

export const ScrollWrapper = styled.div`
  ${setTypography('caption')}
  position: absolute;
  top: 60%;
  width: 150px;
  ${mediaMobile(`
    display: none;
  `)}
`;
export const Scroll = styled.div`
  position: absolute;
  top: -50%;
  left: 0;
`;

export const BgImage = styled.div<{
  imgOffsetX: number;
  imgOffsetY: number;
  imgHeightRatio: number;
}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ imgOffsetX }) => imgOffsetX}px;
  img {
    height: ${({ imgHeightRatio }) => imgHeightRatio}vh;
    opacity: 20%;
  }
`;

export const LayoutWhoWeAre = styled.div<{ wwaWidth: number }>`
  position: relative;
  min-height: 65vh;
  width: ${({ wwaWidth }) => wwaWidth}px;
  ${rfs(`${calcPxToRem(50)}rem`, `margin-right`)}
  > div :nth-child(1) {
    ${setTypography('subtitle2')}
    ${rfs(`${calcPxToRem(50)}rem`, `margin-bottom`)}
  }
  > div :nth-child(2) {
    ${setTypography('heading3')}
    line-height: 0.95;
    margin-left: 5vw;
    > div :nth-child(1) {
      display: inline-block;
    }
  }
  > div :nth-child(3) {
    max-width: 300px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-25%, -50%);
    > div :nth-child(1) {
      ${setTypography('heading5')}
      margin-bottom: 16px;
    }
    > div :nth-child(2) {
      ${setTypography('body2')}
    }
  }
`;

export const LayoutHowWeWork = styled.div<{ hwwWidth: number }>`
  position: relative;
  min-height: 65vh;
  width: ${({ hwwWidth }) => hwwWidth}px;
  ${rfs(`${calcPxToRem(50)}rem`, `margin-right`)}
  > div :nth-child(1) {
    ${setTypography('subtitle2')}
    ${rfs(`${calcPxToRem(50)}rem`, `margin-bottom`)}
  }
  > div :nth-child(2) {
    ${rfs(`${calcPxToRem(25)}rem`, `margin-bottom`)}
    > div :nth-child(1) {
      ${setTypography('heading1')}
      display: inline-block;
    }
  }
  > div :nth-child(3) {
    ${rfs(`${calcPxToRem(100)}rem`, `margin-left`)}
    ${rfs(`${calcPxToRem(25)}rem`, `margin-bottom`)}
    > div :nth-child(1) {
      ${setTypography('heading1')}
      display: inline-block;
    }
  }
  > div :nth-child(4) {
    ${rfs(`${calcPxToRem(200)}rem`, `margin-left`)}
    ${rfs(`${calcPxToRem(100)}rem`, `margin-bottom`)}

    > div :nth-child(1) {
      ${setTypography('heading1')}
      display: inline-block;
    }
  }
  > div :nth-child(5) {
    ${setTypography('caption')}
    max-width: 250px;
  }
`;

export const LayoutOurClients = styled.div<{}>`
  position: relative;
  display: flex;
  ${rfs(`${calcPxToRem(190)}rem`, `margin-right`)}
  > div :nth-child(1) {
    > div :nth-child(1) {
      ${setTypography('heading4')}
      ${rfs(`${calcPxToRem(50)}rem`, `margin-bottom`)}
    }
    > div :nth-child(2) {
      ${setTypography('caption')}
      min-width: 250px;
    }
  }
  > div :nth-child(2) {
    display: flex;
    flex-direction: column;
    ${rfs(`${calcPxToRem(90)}rem`, `row-gap`)}
    ${rfs(`${calcPxToRem(100)}rem`, `margin-left`)}
    > div {
      display: flex;
      > div {
        ${rfs(`${calcPxToRem(100)}rem`, `margin-left`)}
      }
    }
    > div :nth-child(2) {
      ${rfs(`-${calcPxToRem(100)}rem`, `margin-left`)}
    }
  }
`;

export const LayoutOurTeam = styled.div<{}>`
  position: relative;
  display: flex;
  ${rfs(`${calcPxToRem(190)}rem`, `margin-right`)}
  > div :nth-child(1) {
    ${rfs(`${calcPxToRem(50)}rem`, `margin-right`)}
    > div :nth-child(1) {
      ${setTypography('heading4')}
      ${rfs(`${calcPxToRem(50)}rem`, `margin-bottom`)}
    }
    > div :nth-child(2) {
      ${setTypography('caption')}
      min-width: 250px;
    }
  }
  > div :nth-child(2) {
    display: flex;
    flex-direction: column;
    ${rfs(`${calcPxToRem(90)}rem`, `row-gap`)}
    ${rfs(`${calcPxToRem(100)}rem`, `margin-left`)}
    > div {
      display: flex;
      > div {
        ${rfs(`${calcPxToRem(100)}rem`, `margin-left`)}
      }
    }
    > div :nth-child(2) {
      ${rfs(`-${calcPxToRem(100)}rem`, `margin-left`)}
    }
  }
`;

export const Menu = styled.div`
  display: none;
  cursor: pointer;
  ${setTypography('caption')}
  ${mediaMobile(`
    display: none;
  `)}
  display: flex;
  column-gap: 16px;
  > div {
    display: flex;
    align-items: center;
    min-height: 33px;
    min-width: 150px;
    border-bottom: 1px;
    border-style: solid;
    border-color: ${colors.pattensBlue10};
  }
`;
